@mixin border-radius-s {
    border-radius: $border-radius-s;
}

@mixin border-radius-m {
    border-radius: $border-radius-m;
}

@mixin border-radius-lg {
    border-radius: $border-radius-lg;
}
