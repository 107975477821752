    @import "styles/variables";
    @import "styles/mixins";

.bar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0.375rem;
    background: var(--prezly-accent-color);
    animation: grow ease-out 15s;
    z-index: 999;

    &.visible {
        display: block;
    }
}

@keyframes grow {
    0% {
        width: 0%;
    }

    20% {
        width: 80%;
    }

    100% {
        width: 100%;
    }
}
